<template>
  <div class="header">
    <div class="new_menu efs_menu">
      <div class="efs_logo">
        <router-link :to="'/'" custom v-slot="{ navigate }">
          <a @click="fnOnClickLogo" role="link">
            <img src="../assets/img/etners-main/logo_efs.png" style="cursor: pointer;">
          </a>
        </router-link>
      </div>
      
      <div class="menu1">
        <ul>
          <router-link  v-for="menu in menuData.depth1MenuList" :key="menu.menuId"
                        :to="menu.urlLink"
                        custom
                        v-slot="{ navigate }"
                        @click.native="e => fnOnClickMenu(e, menu.menuId, null)">
            <!-- 클릭한 메뉴 depth1메뉴에 active 활성화 스타일 class 처리 -->
            <li @click="navigate" role="link">
              <a :class="$route.path.split('/')[1] === (menu.urlLink).split('/')[1] ? ' active on' : ''">{{ menu.menuNm }}</a>
              <ol class="sub_menu" v-if="menuData.depth2MenuObj[menu.menuId] !== undefined && menuData.depth2MenuObj[menu.menuId].length > 0">
                <router-link  v-for="subMenu in menuData.depth2MenuObj[menu.menuId]" :key="subMenu.menuId"
                              :to="subMenu.urlLink"
                              custom
                              v-slot="{ navigate }"
                              @click.native="e => fnOnClickMenu(e, menu.menuId, subMenu.menuId)">
                  <li :class="activeDepth2MenuId === subMenu.menuId ? ' on' : ''" @click="navigate" role="link">{{ subMenu.menuNm }}</li>
                </router-link>
              </ol>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="right" >
        <ul>
          <li class="name">
           <span class="txt_name">{{ loginUserData.userNm }}</span>님
          </li>

          <li @click="$router.push({name : 'BoardList'})">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" width="21px">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>공지사항
          </li>
          <li @click="$router.push({name : 'MyPageDetail'})">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" style="height:22px" >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>마이페이지
          </li>
         <li @click="fnLogout">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" style="height:22px" >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>로그아웃
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import ApiConfig from '../api/api.config'
import $ from 'jquery'
import { mapGetters } from 'vuex'

export default {
  computed : {
    ...mapGetters(['loginUserData', 'menuData', 'linkLocaNm']),
  },
  created() {
    this.$router.replace({ query: null }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  },
  mounted() {

    // 메뉴 animation
    // let $firstmenu = $('.menu1 > ul'), $header = $('.menu1'), $submenu = $('.menu1 .sub_menu')
    let $header = $('.menu1')
    $header.mouseenter(function() {

      $header.addClass('menubdn')
      $header.stop().animate({
        height: '350px'
      }, 200)
    }).mouseleave(function() {

      $header.stop().animate({
        height: '80px'
      }, 200, function() {

        // when completed
        $header.removeClass('menubdn')
      })
    })
  },
  methods: {
    // 1depth, 2depth 메뉴 클릭 이벤트
    fnOnClickMenu: function(e, activeDepth1MenuId, activeDepth2MenuId) {
      e.stopPropagation()

      this.activeDepth1MenuId = activeDepth1MenuId
      this.activeDepth2MenuId = activeDepth2MenuId
    },
    fnRedirectMainUrl: function () {

      this.activeDepth1MenuId = ''

      let mainPath = '/admin/main'

      this.$router.push({
        path: mainPath,
      })
    },
    // 로그아웃 처리
    fnLogout: function () {
      const vm = this
      kendo.confirm('로그아웃하시겠습니까?').done(function () {

        vm.$store.dispatch('LOGOUT').then(() => {
          window.location.href = `/login`
        })
      })
    },
    fnOnClickLogo: function (){

      if(this.$route.name === 'DashBoard'){
        this.$router.go()
      }else {
        this.$router.push({name: 'DashBoard'})
      }
    },
  },
  data: function () {
    return {
      activeDepth1MenuId: '',
      activeDepth2MenuId: '',
    }
  },

}
</script>
<style scoped="">
</style>
